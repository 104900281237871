import React, { useEffect, useMemo, useState } from "react";
import { Checkbox } from "@mui/material";
import Box from "@mui/material/Box";
import Fade from "@mui/material/Fade";
import Modal from "@mui/material/Modal";
import axios from "axios";
import _ from "lodash";
import moment from "moment-timezone";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { useFilePicker } from "use-file-picker";

import addSvg from "../../../assets/add.svg";
import checkSvg from "../../../assets/check.svg";
import Close from "../../../assets/close.svg";
import CloseDark from "../../../assets/closedark.svg";
import redClose from "../../../assets/red-close.svg";
import unCheckSvg from "../../../assets/uncheck.svg";
import uploadBanner from "../../../functions/events/uploadBanner";
import { addEventModalState } from "../../../recoil/modal";
import { previewModalImgState } from "../../../recoil/previewModal";
import { darkModeState } from "../../../recoil/settings";
import { UserAccessTokenState, UserDataState } from "../../../recoil/user";
import axiosErrorHandler from "../../../utils/axiosErrorHandler";
import getDateFormatted from "../../../utils/getDateFormatted";
import { getSeatSelectionColor } from "../../../utils/getSeatSelectionColor";
import translate from "../../../utils/temp/translate";
import { isValidUrl } from "../../../utils/validators";
import CreateEventSeatDetails from "../../events/CreateEventSeatDetails";
import EventDetails from "../../events/EventDetails";
import Button from "../../general/Button";
import CircularLoader from "../../general/CircularLoader";
import Input from "../../general/Input";
import Select from "../../general/Select";
import { ErrorToast, SuccessToast } from "../../general/ToastNotification";
import Tooltip from "../../general/Tooltip";

import PreviewModal from "./PreviewModal";
import CustomSlider from "./Slider";

import styles from "../../../styles/modals/Modal.module.css";

const formatLocalDateTime = (date) => {
  return moment(date.replace("Z", "")).format("DD MMM YYYY, h:mmA");
};

const initialValues = {
  country: "Singapore",
  eventBanner: "",
  eventEndDate: null,
  eventName: "",
  eventStartDate: null,
  seatMap: "",
  seatMapDesigned: false,
  selectableSeats: false,
  venueLocation: "",
  venueName: "",
};

const initialCategoryValues = {
  categoryName: "",
  originalStock: "",
  price: "",
  quantity: "",
  sectionName: "",
};

const step2InputsInitialValues = {
  itemsIncluded: "",
  packageName: "",
  ticketCategory: "",
  ticketPrice: "",
};

const step3InputsInitialValues = {
  maxCheckoutPerAccount: 0,
  maxTicketPerTransaction: 0,
  releaseType: "fcfs",
  saleEndDate: "",
  saleName: "",
  saleStartDate: "",
  ticketForSale: "",
};

const style = (dark) => {
  return {
    bgcolor: dark ? "#323A52" : "#F6F7FF",
    border: "none",
    borderRadius: "0.9375rem",
    boxShadow: "0rem 0rem 0.625rem rgba(0, 0, 0, 0.15)",
    color: !dark ? "#323A52" : "#F6F7FF",
    left: "50%",
    maxWidth: "72.0625rem",
    outline: "none",
    padding: "1.25rem 0",
    position: "absolute",
    top: "50%",
    transform: "translate(-50%, -50%)",
    width: "100%",
  };
};

const ticketLimitsOptions = () => {
  const options = [];
  for (let i = 1; i < 10; i++) {
    options.push({ label: i, value: i });
  }
  return options;
};

export default function EventDetailsModal() {
  const [selectedCategory, setSelectedCategory] = useState(null);
  const dark = useRecoilValue(darkModeState);
  const [open, setOpen] = useRecoilState(addEventModalState);
  const [currentStep, setCurrentStep] = useState(1);
  const [fields, setFields] = useState(initialValues);
  const userData = useRecoilValue(UserDataState);
  const [categoryFields, setCategoryFields] = useState(initialCategoryValues);
  const [step2Inputs, setStep2Inputs] = useState(step2InputsInitialValues);
  const [step3Inputs, setStep3Inputs] = useState(step3InputsInitialValues);
  const [categoryList, setCategoryList] = useState([]);
  const [packageList, setPackageList] = useState([]);
  const [saleList, setSaleList] = useState([]);
  const [eventsDetail, setEventsDetail] = useState("");
  const accessToken = useRecoilValue(UserAccessTokenState);
  const [loading, setLoading] = useState(false);
  const [bannerData, setBannerData] = useState(initialValues);
  const [uploading, setUploading] = useState(false);
  const [seatMaps, setSeatMaps] = useState([]);
  const [venues, setVenues] = useState([]);
  const [venueSectionData, setVenueSectionData] = useState([]);
  const [previewImageUrl, setPreviewImageUrl] =
    useRecoilState(previewModalImgState);
  const [placeholder, setPlaceholder] = useState(
    <>
      Table 1 (4pax) - $999 <br />
      Hennessy 2 Bottles <br />
      Table 2 (4 pax)
    </>
  );
  const [price, setPrice] = useState(0);

  const getSeatMapsOptions = () => {
    const options = [];
    const seatmapResults = fields.selectableSeats ? venues : seatMaps;
    seatmapResults.forEach((seatMap) => {
      options.push({
        label: seatMap.name,
        value: fields.selectableSeats ? seatMap._id : seatMap.url,
      });
    });
    return options;
  };

  useEffect(() => {
    try {
      (async () => {
        const resp = await axios.get(
          `${process.env.REACT_APP_ORGANISER_API}/event/seatmap/${fields.country}/${userData._id}`
        );
        if (!resp.data) {
          throw new Error(resp.error.message);
        }
        if (resp.error) {
          throw new Error(resp.error);
        }
        setSeatMaps(resp.data);
      })();
    } catch (error) {
      console.log(error);
    }
  }, [fields.country]);

  useEffect(() => {
    if (fields.selectableSeats) {
      try {
        (async () => {
          const resp = await axios.get(
            `${process.env.REACT_APP_ORGANISER_API}/event/venues/${fields.country}`
          );
          if (!resp.data) {
            throw new Error(resp.error.message);
          }
          if (resp.error) {
            throw new Error(resp.error);
          }
          setVenues(resp.data);
        })();
      } catch (error) {
        console.log(error);
      }
    }
  }, [fields.country, fields.selectableSeats]);

  useEffect(() => {
    if (
      fields.selectableSeats &&
      fields.seatMap &&
      !isValidUrl(fields.seatMap)
    ) {
      try {
        (async () => {
          const resp = await axios.get(
            `${process.env.REACT_APP_ORGANISER_API}/event/venue-sections/${fields.seatMap}`
          );
          if (!resp.data) {
            throw new Error(resp.error.message);
          }
          if (resp.error) {
            throw new Error(resp.error);
          }
          setVenueSectionData(resp.data);
        })();
      } catch (error) {
        console.log(error);
      }
    }
  }, [fields.seatMap, fields.selectableSeats]);

  useEffect(() => {
    if (fields.selectableSeats) {
      setFields({
        ...fields,
        seatMap: venues?.[0]?._id || "",
      });
    } else if (!fields.selectableSeats) {
      setFields({
        ...fields,
        seatMap: "",
      });
    }
  }, [fields.selectableSeats]);

  useEffect(() => {
    if (fields.seatMap) {
      const seatmapResults = fields.selectableSeats ? venues : seatMaps;

      const selectedSeatMap = fields.selectableSeats
        ? venues.find((seatMap) => seatMap._id === fields.seatMap)
        : seatMaps.find((seatMap) => seatMap.url === fields.seatMap);

      if (selectedSeatMap) {
        setFields((prev) => ({
          ...prev,
          venueName: selectedSeatMap?.name,
        }));
      } else {
        setFields((prev) => ({
          ...prev,
          seatMapDesigned:
            fields.selectableSeats ||
            seatmapResults.some((seatMap) => seatMap.url === fields.seatMap),
        }));
      }

      setPreviewImageUrl(selectedSeatMap?.url);
    } else {
      setFields((prev) => ({
        ...prev,
        venueName: "",
      }));
    }
  }, [fields.seatMap]);

  useEffect(() => {
    const sections = venueSectionData.map((section) => ({
      categoryName: section.categoryName,
      color: section.color,
      originalStock: section.seatsAvailable,
      quantity: section.seatsAvailable,
      sectionName: section.sectionName,
      selectable: true,
      ticketTypes: [
        {
          itemsIncluded: "",
          price: "",
          ticketSection: section.sectionName,
          ticketTypeName: section.sectionName,
        },
      ],
    }));
    setCategoryList(sections);
  }, [venueSectionData]);

  const handleCategoryPriceChange = (index, price) => {
    let newList = [...categoryList];
    newList[index].ticketTypes[0].price = price;
    setCategoryList(newList);
  };

  const handleSelectableChange = (index, checked) => {
    let newList = [...categoryList];
    newList[index].selectable = checked;
    setCategoryList(newList);
  };

  const handleChange = (e) => {
    setFields({
      ...fields,
      [e.target.name]: e.target.value,
    });
  };

  const handleCategoryChange = (e) => {
    setCategoryFields({
      ...categoryFields,
      [e.target.name]: e.target.value,
    });
  };
  const handleStep2Change = (e) => {
    setStep2Inputs({
      ...step2Inputs,
      [e.target.name]: e.target.value,
    });
  };

  const handleStep3Change = (e) => {
    setStep3Inputs({
      ...step3Inputs,
      [e.target.name]: e.target.value,
    });
  };

  const handleClose = () => {
    setOpen(false);
    setBannerData(initialValues);
    setFields(initialValues);
    setCurrentStep(1);
    setCategoryList([]);
    setPackageList([]);
    setSaleList([]);
    setStep2Inputs(step2InputsInitialValues);
    setStep3Inputs(step3InputsInitialValues);
    setEventsDetail("");
  };

  const handleUpload = async (file, isSeatmap = false) => {
    if (!uploading) {
      try {
        setUploading(true);
        const url = await uploadBanner(accessToken, file, isSeatmap);
        if (isSeatmap) {
          setFields((prev) => ({
            ...prev,
            seatMap: url,
            seatMapDesigned: false,
          }));
          setBannerData((prev) => ({ ...prev, uploadedSeatMap: url }));
        } else {
          setFields({ ...fields, eventBanner: url });
          setBannerData((prev) => ({ ...prev, eventBanner: url }));
        }
      } catch (e) {
        const msg = axiosErrorHandler(e);
        ErrorToast("Upload Error", msg);
      } finally {
        setUploading(false);
      }
    }
  };

  const [
    openFileSelectorForBanner,
    { filesContent, loading: bannerSelectLoading },
  ] = useFilePicker({
    accept: "image/*",
    limitFilesConfig: { max: 1 },
    // minFileSize: 0.1, // in megabytes
    maxFileSize: 50,

    multiple: false,

    onFilesSuccessfulySelected: async ({ plainFiles }) => {
      // this callback is called when there were no validation errors
      await handleUpload(plainFiles[0]);
    },
    readAs: "DataURL",
  });

  const [
    openFileSelectorForSvg,
    { filesContent: svgFilesContent, loading: svgSelectLoading },
  ] = useFilePicker({
    accept: "image/svg+xml",
    limitFilesConfig: { max: 1 },
    // minFileSize: 0.1, // in megabytes
    maxFileSize: 50,

    multiple: false,

    onFilesSuccessfulySelected: async ({ plainFiles }) => {
      // this callback is called when there were no validation errors
      await handleUpload(plainFiles[0], true);
    },
    readAs: "DataURL",
  });

  const isStep1Disabled = !(
    fields.eventName &&
    fields.country &&
    fields.venueName &&
    fields.venueLocation &&
    fields.eventStartDate &&
    fields.eventBanner &&
    fields.seatMap &&
    fields.eventEndDate
  );

  const handleNextStep = () => {
    if (currentStep === 1) {
      if (isStep1Disabled) {
        return ErrorToast("Please provide all event details");
      }
      setBannerData(fields);
    }
    setCurrentStep(currentStep + 1);
  };
  const handlePrevStep = () => {
    setCurrentStep(currentStep - 1);
  };

  const handleBannerDataContinue = () => {
    console.log(fields);
    if (isStep1Disabled) {
      return ErrorToast("Please provide all event details");
    }
    setBannerData(fields);
  };

  const handleRemoveCategory = (index) => {
    const list = [...categoryList];
    list.splice(index, 1);
    setCategoryList(list);
  };

  const isStep3Disabled = !(
    step3Inputs.saleName &&
    step3Inputs.releaseType &&
    step3Inputs.saleStartDate &&
    step3Inputs.saleEndDate &&
    step3Inputs.ticketForSale
  );

  const handleCreateCategory = () => {
    if (isStep1Disabled) {
      return ErrorToast("Error", "Please provide all event data");
    }
    if (
      !categoryFields.categoryName ||
      !categoryFields.quantity ||
      !categoryFields.sectionName ||
      !categoryFields.price ||
      isStep1Disabled
    ) {
      return ErrorToast("Error", "Please provide all data");
    }
    if (
      categoryList.some((cat) => cat.sectionName === categoryFields.sectionName)
    ) {
      return ErrorToast(
        "Error",
        `Section ${categoryFields.sectionName} already exists`
      );
    }
    const newCategory = {
      categoryName: categoryFields.categoryName,
      originalStock: categoryFields.quantity,
      quantity: categoryFields.quantity,
      sectionName: categoryFields.sectionName,
      ticketTypes: [
        {
          itemsIncluded: "",
          price: categoryFields.price,
          ticketSection: categoryFields.sectionName,
          ticketTypeName: categoryFields.sectionName,
        },
      ],
    };

    setCategoryList([...categoryList, newCategory]);
    setCategoryFields(initialCategoryValues);
  };

  const handleAddPackage = () => {
    if (
      !step2Inputs.itemsIncluded ||
      !step2Inputs.ticketPrice ||
      !step2Inputs.packageName
    ) {
      return ErrorToast("Error", "Please provide all data");
    }
    const newTicketType = {
      itemsIncluded: step2Inputs.itemsIncluded,
      price: step2Inputs.ticketPrice,
      ticketSection: categoryList[step2Inputs.ticketCategory].sectionName,
      ticketTypeName: step2Inputs.packageName,
    };

    let prevCategoryList = [...categoryList];
    let prevTicketTypes =
      prevCategoryList[step2Inputs.ticketCategory].ticketTypes;
    prevCategoryList[step2Inputs.ticketCategory].ticketTypes = [
      ...prevTicketTypes,
      newTicketType,
    ];

    setCategoryList(prevCategoryList);
    setPackageList([...packageList, newTicketType]);
    setStep2Inputs((prev) => ({
      ...prev,
      itemsIncluded: "",
      packageName: "",
      ticketPrice: "",
    }));
  };

  const handleRemovePackage = (categoryIndex, typeIndex) => {
    let prevCategoryList = [...categoryList];
    let prevTicketTypes = prevCategoryList[categoryIndex].ticketTypes;
    if (prevTicketTypes.length === 1) {
      return ErrorToast(
        "Error",
        "Atleast one package for each section is required"
      );
    }
    prevTicketTypes.splice(typeIndex, 1);
    prevCategoryList[categoryIndex].ticketTypes = [...prevTicketTypes];
    setCategoryList(prevCategoryList);
  };

  const handleAddSale = () => {
    if (isStep3Disabled) return ErrorToast("Error", "Please provide all data");

    const newSale = {
      maxCheckoutPerAccount: step3Inputs.maxCheckoutPerAccount,
      maxTicketPerTransaction: step3Inputs.maxTicketPerTransaction,
      releaseType: step3Inputs.releaseType,
      saleEndDate: step3Inputs.saleEndDate.toString() + "Z",
      saleName: step3Inputs.saleName,
      saleStartDate: step3Inputs.saleStartDate.toString() + "Z",
      ticketForSale: step3Inputs.ticketForSale,
    };

    setSaleList([...saleList, newSale]);
    setStep3Inputs(step3InputsInitialValues);
  };

  const handleRemoveSale = (index) => {
    const list = [...saleList];
    list.splice(index, 1);
    setSaleList(list);
  };

  const previewEvent = async () => {
    const eventData = {
      country: fields.country,
      description: eventsDetail,
      eventBanner: fields.eventBanner,
      eventEndDate: fields.eventEndDate.toString() + "Z",
      eventName: fields.eventName,
      eventStartDate: fields.eventStartDate.toString() + "Z",
      sales: saleList,
      seatMap: fields.seatMap,
      seatMapDesigned: fields.seatMapDesigned,
      sections: categoryList,
      venueLocation: fields.venueLocation,
      venueName: fields.venueName,
    };

    const payload = JSON.stringify(eventData);
    const previewUrl = `https://buyer-view.pages.dev`;

    window.open(
      `${previewUrl}?payload=${btoa(unescape(encodeURIComponent(payload)))}`,
      "_blank"
    );
  };

  const addEvent = async () => {
    try {
      setLoading(true);
      const eventData = {
        country: fields.country,
        description: eventsDetail,
        eventBanner: fields.eventBanner,
        eventEndDate: fields.eventEndDate.toString() + "Z",
        eventName: fields.eventName,
        eventStartDate: fields.eventStartDate.toString() + "Z",
        sales: saleList,
        seatMap: fields.selectableSeats ? previewImageUrl : fields.seatMap,
        seatMapDesigned: fields.seatMapDesigned,
        sections: categoryList,
        selectableSeats: fields.selectableSeats,
        venueId: fields.seatMap,
        venueLocation: fields.venueLocation,
        venueName: fields.venueName,
      };

      await axios.post(
        `${process.env.REACT_APP_ORGANISER_API}/event/create`,
        eventData,
        {
          headers: {
            token: accessToken,
          },
        }
      );

      SuccessToast("Success", "Event created successfully!");
      setOpen(false);
      handleClose();
    } catch (error) {
      const msg = axiosErrorHandler(error);
      ErrorToast("Error", msg);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setSelectedCategory(step2Inputs.ticketCategory);
  }, [step2Inputs.ticketCategory]);

  const flatPackageList = useMemo(
    () =>
      _.flatMap(
        categoryList.map((category, categoryIndex) => {
          return category.ticketTypes.map((ticketType, typeIndex) => {
            return { ...ticketType, categoryIndex, typeIndex };
          });
        })
      ),
    [categoryList]
  );

  return (
    <div>
      <PreviewModal />
      <Modal
        className={`${styles.modal} ${dark ? "dark" : ""}`}
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
      >
        <Fade in={open}>
          <Box sx={() => style(dark)}>
            <div className={styles.addEventModal}>
              <div className={styles.header}>
                <div>
                  {translate(`Adding New Event (Step ${currentStep} of 4)`)}
                </div>
                <img
                  onClick={handleClose}
                  src={dark ? CloseDark : Close}
                  alt=""
                />
              </div>
              {currentStep !== 4 ? (
                <div className={styles.banner}>
                  <EventDetails data={bannerData} />
                </div>
              ) : (
                <div className={styles.bannerContainer}>
                  <EventDetails data={fields} />
                  {saleList.map((sale, index) => (
                    <CreateEventSeatDetails key={index} data={sale} />
                  ))}
                </div>
              )}

              {currentStep === 1 && (
                <div className={styles.content}>
                  <div className={styles.grid}>
                    <div>
                      <div className={styles.label}>
                        {translate("Event Date Start")}
                      </div>

                      <Input
                        dark={dark}
                        name="eventStartDate"
                        type="datetime-local"
                        value={fields.eventStartDate}
                        updateValue={handleChange}
                        placeholder={"Enter Name"}
                      />
                    </div>
                    <div>
                      <div className={styles.label}>
                        {translate("Event Date End")}
                      </div>

                      <Input
                        dark={dark}
                        name="eventEndDate"
                        type="datetime-local"
                        value={fields.eventEndDate}
                        updateValue={handleChange}
                        placeholder={"Enter Name"}
                      />
                    </div>

                    <div>
                      <div className={styles.label}>
                        {translate("Event Name")}
                      </div>

                      <Input
                        dark={dark}
                        name="eventName"
                        type="text"
                        value={fields.eventName}
                        updateValue={handleChange}
                        placeholder={"Enter Name"}
                      />
                    </div>

                    <div>
                      <div className={styles.label}>{translate("Country")}</div>

                      <Select
                        options={[{ label: "Singapore", value: "Singapore" }]}
                        dark={dark}
                        name="country"
                        type="text"
                        value={fields.country}
                        updateValue={(e) =>
                          setFields({
                            ...fields,
                            country: e,
                          })
                        }
                        placeholderText={"Enter Country"}
                      />
                    </div>
                  </div>

                  <div className={styles.grid}>
                    <div>
                      <div className={styles.label}>
                        {translate("Seat Selection")}
                      </div>

                      <Select
                        options={[
                          {
                            label: "Yes",
                            value: true,
                          },
                          {
                            label: "No",
                            value: false,
                          },
                        ]}
                        dark={dark}
                        name="selectableSeats"
                        type="text"
                        value={fields.selectableSeats}
                        updateValue={(e) =>
                          setFields({
                            ...fields,
                            selectableSeats: e,
                          })
                        }
                        placeholderText={"Choose"}
                      />
                    </div>

                    <div>
                      <div className={styles.label}>
                        {translate("Venue Location")}
                      </div>

                      <Input
                        dark={dark}
                        name="venueLocation"
                        type="text"
                        value={fields.venueLocation}
                        updateValue={handleChange}
                        placeholder={"Enter Location"}
                      />
                    </div>

                    <div>
                      <div className={styles.label}>
                        {translate("Event Banner")}
                        <Tooltip text={"Event Banner"} />
                      </div>

                      <div className={styles.uploadWrapper}>
                        <div
                          className={styles.uploadText}
                          onClick={
                            !uploading ? openFileSelectorForBanner : null
                          }
                        >
                          {fields.eventBanner
                            ? filesContent?.[0]?.name
                            : "Upload Image"}
                        </div>
                      </div>
                    </div>

                    <div className={styles.seatMap}>
                      <div className={styles.label}>
                        {translate("Seat Map")}
                      </div>

                      <Select
                        hideUpload={fields.selectableSeats}
                        icon
                        none
                        uploadedUrl={bannerData.uploadedSeatMap}
                        uploadLabel="Upload Seat Map"
                        uploadedFileName={svgFilesContent?.[0]?.name}
                        handleUpload={
                          !uploading ? openFileSelectorForSvg : null
                        }
                        options={getSeatMapsOptions()}
                        dark={dark}
                        name="seatMap"
                        type="text"
                        value={fields.seatMap}
                        updateValue={(e) =>
                          setFields({
                            ...fields,
                            seatMap: e,
                            seatMapDesigned: true,
                          })
                        }
                        placeholderText={""}
                      />
                    </div>
                  </div>

                  <div className={styles.row}>
                    {uploading && <CircularLoader loading={uploading} />}
                    <Button
                      imgSrc={<img src={addSvg} alt="add" />}
                      text={`Save`}
                      onClick={handleBannerDataContinue}
                    />
                  </div>

                  {fields.selectableSeats ? (
                    <>
                      <div
                        className={`${styles.gridContainer} ${styles.headers} ${styles.seatSelection}`}
                      >
                        <div />
                        <div className={styles.header}>Categories Name</div>
                        <div className={styles.header}>Section</div>
                        <div className={styles.header}>Price</div>
                        <div className={styles.header}>Quantity</div>
                        <div className={styles.header}>Selectable</div>
                        <div className={styles.header} />
                      </div>

                      <div className={styles.seatSelectionContainer}>
                        {categoryList.map((item, index) => (
                          <div
                            key={index}
                            className={`${styles.gridContainer} ${styles.values} ${styles.seatSelection}`}
                          >
                            <div className={styles.colorBlockContainer}>
                              <div
                                style={{
                                  backgroundColor: item.color || "#FF7A03",
                                }}
                                className={styles.colorBlock}
                              />
                            </div>
                            <div className={styles.value}>
                              {item.categoryName}
                            </div>
                            <div className={styles.value}>
                              {item.sectionName}
                            </div>
                            <div className={styles.priceInputContainer}>
                              <input
                                type="number"
                                name="price"
                                value={item.ticketTypes[0].price}
                                onChange={(e) =>
                                  handleCategoryPriceChange(
                                    index,
                                    e.target.value
                                  )
                                }
                                className={styles.priceInput}
                              />
                              $SGD
                            </div>
                            <div className={styles.value}>{item.quantity}</div>
                            <div className={styles.value}>
                              <Checkbox
                                checked={item.selectable}
                                onChange={(e) =>
                                  handleSelectableChange(
                                    index,
                                    e.target.checked
                                  )
                                }
                                icon={
                                  <img
                                    className={styles.closeButton}
                                    src={unCheckSvg}
                                    alt="un-checked"
                                  />
                                }
                                checkedIcon={
                                  <img
                                    className={styles.closeButton}
                                    src={checkSvg}
                                    alt="checked"
                                  />
                                }
                              />
                            </div>

                            <div className={styles.value}>
                              <img
                                onClick={() => handleRemoveCategory(index)}
                                className={styles.closeButton}
                                src={redClose}
                                alt="close"
                              />
                            </div>
                          </div>
                        ))}
                      </div>
                    </>
                  ) : (
                    <>
                      <div className={styles.grid}>
                        <div>
                          <div className={styles.label}>
                            {translate("Category Name")}
                            <Tooltip text={"Category"} />
                          </div>

                          <Input
                            dark={dark}
                            name="categoryName"
                            type="text"
                            value={categoryFields.categoryName}
                            updateValue={handleCategoryChange}
                            placeholder={"VIP"}
                          />
                        </div>
                        <div>
                          <div className={styles.label}>
                            {translate("Section")}
                            <Tooltip text={"Section"} />
                          </div>

                          <Input
                            dark={dark}
                            name="sectionName"
                            type="text"
                            value={categoryFields.sectionName}
                            updateValue={handleCategoryChange}
                            placeholder={"ABC"}
                          />
                        </div>
                        <div>
                          <div className={styles.label}>
                            {translate("Price")}
                          </div>

                          <Input
                            dark={dark}
                            name="price"
                            type="number"
                            value={categoryFields.price}
                            updateValue={handleCategoryChange}
                            placeholder={"$40"}
                          />
                        </div>
                        <div>
                          <div className={styles.label}>
                            {translate("Quantity")}
                          </div>

                          <Input
                            dark={dark}
                            name="quantity"
                            type="number"
                            value={categoryFields.quantity}
                            updateValue={handleCategoryChange}
                            placeholder={"4"}
                          />
                        </div>
                      </div>

                      <div className={styles.row}>
                        <Button
                          onClick={handleCreateCategory}
                          imgSrc={<img src={addSvg} alt="add" />}
                          text={`Add Categories`}
                        />
                      </div>

                      <div
                        className={`${styles.gridContainer} ${styles.headers}`}
                      >
                        <div className={styles.header}>Categories Name</div>
                        <div className={styles.header}>Section</div>
                        <div className={styles.header}>Price</div>
                        <div className={styles.header}>Quantity</div>

                        <div className={styles.header}></div>
                      </div>

                      {categoryList.map((item, index) => (
                        <div
                          key={index}
                          className={`${styles.gridContainer} ${styles.values}`}
                        >
                          <div className={styles.value}>
                            {item.categoryName}
                          </div>
                          <div className={styles.value}>{item.sectionName}</div>
                          <div className={styles.value}>
                            {item.ticketTypes[0].price}
                          </div>
                          <div className={styles.value}>{item.quantity}</div>
                          <div className={styles.value}>
                            <img
                              onClick={() => handleRemoveCategory(index)}
                              className={styles.closeButton}
                              src={redClose}
                              alt="close"
                            />
                          </div>
                        </div>
                      ))}
                    </>
                  )}
                  <div className={styles.bottomBtns}>
                    <div />
                    <Button
                      onClick={handleNextStep}
                      text={`Continue to Step ${currentStep + 1}`}
                      disabled={isStep1Disabled || !categoryList.length}
                    />
                  </div>
                </div>
              )}

              {currentStep === 2 && (
                <div className={styles.content}>
                  <div className={styles.packageGrid}>
                    <div>
                      <div className={styles.label}>
                        {translate("Package Name")}
                      </div>
                      <Input
                        dark={dark}
                        name="packageName"
                        type="text"
                        value={step2Inputs.packageName}
                        updateValue={handleStep2Change}
                        placeholder={"Super VIP"}
                      />
                    </div>
                    <div>
                      <div className={styles.label}>
                        {translate("Ticket Price")}
                      </div>
                      <Input
                        dark={dark}
                        name="ticketPrice"
                        type="number"
                        value={step2Inputs.ticketPrice}
                        updateValue={handleStep2Change}
                        placeholder={"999"}
                      />
                    </div>
                    <div>
                      <div className={styles.label}>
                        {translate("Ticket Section")}
                      </div>
                      <Select
                        options={categoryList.map((category, index) => ({
                          label: category.sectionName,
                          value: index,
                        }))}
                        dark={dark}
                        name="ticketCategory"
                        value={step2Inputs.ticketCategory}
                        updateValue={(e) =>
                          setStep2Inputs({
                            ...step2Inputs,
                            ticketCategory: e,
                          })
                        }
                        placeholderText={"Choose Category"}
                      />
                    </div>
                  </div>
                  <div className={styles.packageGrid}>
                    <div>
                      <div className={styles.label}>
                        {translate("Items Included")}
                      </div>
                      <div className={styles.whatsIncludedWrapper}>
                        <div className={styles.placeholder}>
                          {step2Inputs.itemsIncluded ? "" : placeholder}
                        </div>
                        <textarea
                          onFocus={() => setPlaceholder("")}
                          onBlur={() =>
                            setPlaceholder(
                              <>
                                Table 1 (4pax) - $999 <br />
                                Hennessy 2 Bottles <br />
                                Table 2 (4 pax)
                              </>
                            )
                          }
                          value={step2Inputs.itemsIncluded}
                          onChange={(e) =>
                            setStep2Inputs({
                              ...step2Inputs,
                              itemsIncluded: e.target.value,
                            })
                          }
                          className={styles.itemsIncluded}
                          placeholder=""
                        />
                      </div>
                    </div>

                    <div className={styles.packageFlex}>
                      <div className={styles.row}>
                        <Button
                          onClick={handleAddPackage}
                          imgSrc={<img src={addSvg} alt="add" />}
                          text={`Add Package`}
                        />
                      </div>
                    </div>
                  </div>
                  <CustomSlider
                    packagesData={flatPackageList || []}
                    handleRemovePackage={handleRemovePackage}
                  />

                  <div className={styles.bottomBtns}>
                    <Button onClick={handlePrevStep} text={"Back"} />
                    <Button
                      onClick={handleNextStep}
                      text={`Continue to Step ${currentStep + 1}`}
                    />
                  </div>
                </div>
              )}

              {/* {currentStep === 3 && (
                <div className={styles.content}>
                  <div className={styles.grid}>
                    <div>
                      <div className={styles.label}>
                        {translate("Sale Name")}
                      </div>

                      <Input
                        dark={dark}
                        name="saleName"
                        type="text"
                        value={step3Inputs.saleName}
                        updateValue={handleStep3Change}
                        placeholder={"Enter Name"}
                      />
                    </div>
                    <div>
                      <div className={styles.label}>
                        {translate("Release Type")}
                      </div>
                      <Select
                        options={[{ label: "FCFS", value: "fcfs" }]}
                        dark={dark}
                        name="releaseType"
                        type="text"
                        value={step3Inputs.releaseType}
                        updateValue={(e) =>
                          setStep3Inputs({
                            ...step3Inputs,
                            releaseType: e,
                          })
                        }
                        placeholderText={"Choose Type"}
                      />
                    </div>

                    <div>
                      <div className={styles.label}>
                        {translate("Ticket for sale")}
                      </div>

                      <Input
                        dark={dark}
                        name="ticketForSale"
                        type="number"
                        value={step3Inputs.ticketForSale}
                        updateValue={handleStep3Change}
                        placeholder={"Enter Count"}
                      />
                    </div>
                    <div>
                      <div className={styles.label}>
                        {translate("Max. checkout per account")}
                      </div>

                      <Select
                        options={[
                          {
                            label: "No limit",
                            value: 0,
                          },
                          ...ticketLimitsOptions(),
                        ]}
                        dark={dark}
                        name="maxCheckoutPerAccount"
                        type="text"
                        value={step3Inputs.maxCheckoutPerAccount}
                        updateValue={(e) =>
                          setStep3Inputs({
                            ...step3Inputs,
                            maxCheckoutPerAccount: e,
                          })
                        }
                        placeholderText={"Choose count"}
                      />
                    </div>
                  </div>
                  {step3Inputs.releaseType === "password" && (
                    <div className={styles.threeGrid}>
                      <div>
                        <div className={styles.label}>
                          {translate("Password")}
                        </div>

                        <Input
                          dark={dark}
                          name="password"
                          type="text"
                          placeholder={"Enter Password"}
                        />
                      </div>
                      <div>
                        <div className={styles.label}>
                          {translate("Amount to use")}
                        </div>

                        <Select
                          options={[
                            {
                              label: "1 per account",
                              value: "1",
                            },
                          ]}
                          dark={dark}
                          name="amountToUse"
                          type="text"
                          placeholderText={"Choose count"}
                        />
                      </div>
                    </div>
                  )}
                  {step3Inputs.releaseType === "cardExclusive" && (
                    <div className={styles.threeGrid}>
                      <div>
                        <div className={styles.label}>
                          {translate("Bank Name")}
                        </div>

                        <Input
                          dark={dark}
                          name="bankName"
                          type="text"
                          placeholder={"Enter Bank Name"}
                        />
                      </div>
                      <div>
                        <div className={styles.label}>
                          {translate("First 4 digit/Last 4 digit")}
                        </div>

                        <Input
                          dark={dark}
                          name="digits"
                          type="text"
                          placeholder={"Enter the digits"}
                        />
                      </div>
                    </div>
                  )}
                  {step3Inputs.releaseType === "uniqueLink" && (
                    <div className={styles.threeGrid}>
                      <div>
                        <div className={styles.label}>
                          {translate("Auto-generated unique link")}
                        </div>

                        <Input
                          dark={dark}
                          name="link"
                          type="text"
                          placeholder={"Link"}
                          value={"detail.ingenium.biz"}
                          disabled={true}
                        />
                      </div>
                    </div>
                  )}
                  {step3Inputs.releaseType === "Raffle" && (
                    <div className={styles.threeGrid}>
                      <div>
                        <div className={styles.label}>
                          {translate("Sale Start Date & Time")}
                        </div>

                        <Input
                          dark={dark}
                          name="saleStartDate"
                          type="datetime-local"
                          value={step3Inputs.saleStartDate}
                          updateValue={handleStep3Change}
                          placeholder={"Enter Date"}
                        />
                      </div>

                      <div>
                        <div className={styles.label}>
                          {translate("Sale End Date & Time")}
                        </div>

                        <Input
                          dark={dark}
                          name="saleEndDate"
                          type="datetime-local"
                          value={step3Inputs.saleEndDate}
                          updateValue={handleStep3Change}
                          placeholder={"Enter Date"}
                        />
                      </div>

                      <div>
                        <div className={styles.label}>
                          {translate("Max. ticket per transaction")}
                        </div>
                        <Select
                          options={[
                            {
                              label: "No limit",
                              value: 0,
                            },
                            ...ticketLimitsOptions(),
                          ]}
                          dark={dark}
                          name="maxTicketPerTransaction"
                          type="text"
                          value={step3Inputs.maxTicketPerTransaction}
                          updateValue={(e) =>
                            setStep3Inputs({
                              ...step3Inputs,
                              maxTicketPerTransaction: e,
                            })
                          }
                          placeholderText={"Choose count"}
                        />
                      </div>
                    </div>
                  )}
                  {step3Inputs.releaseType !== "Raffle" && (
                    <div className={styles.grid}>
                      <div>
                        <div className={styles.label}>
                          {translate("Sale Start Date & Time")}
                        </div>

                        <Input
                          dark={dark}
                          name="saleStartDate"
                          type="datetime-local"
                          value={step3Inputs.saleStartDate}
                          updateValue={handleStep3Change}
                          placeholder={"Enter Date"}
                        />
                      </div>
                      <div>
                        <div className={styles.label}>
                          {translate("Sale End Date & Time")}
                        </div>

                        <Input
                          dark={dark}
                          name="saleEndDate"
                          type="datetime-local"
                          value={step3Inputs.saleEndDate}
                          updateValue={handleStep3Change}
                          placeholder={"Enter Date"}
                        />
                      </div>

                      <div>
                        <div className={styles.label}>
                          {translate("Max. ticket per transaction")}
                        </div>
                        <Select
                          options={[
                            {
                              label: "No limit",
                              value: 0,
                            },
                            ...ticketLimitsOptions(),
                          ]}
                          dark={dark}
                          name="maxTicketPerTransaction"
                          type="text"
                          value={step3Inputs.maxTicketPerTransaction}
                          updateValue={(e) =>
                            setStep3Inputs({
                              ...step3Inputs,
                              maxTicketPerTransaction: e,
                            })
                          }
                          placeholderText={"Choose count"}
                        />
                      </div>

                      <div
                        style={{
                          alignItems: "flex-end",
                        }}
                        className={styles.row}
                      >
                        <Button
                          onClick={handleAddSale}
                          imgSrc={<img src={addSvg} alt="add" />}
                          text={`Add Sale`}
                        />
                      </div>
                    </div>
                  )}

                  <div
                    className={`${styles.gridContainer} ${styles.headers} ${styles.addSale}`}
                  >
                    <div className={styles.header}>Sale Name</div>
                    <div className={styles.header}>Release Type</div>
                    <div className={styles.header}>Sale Start</div>
                    <div className={styles.header}>Sale End</div>
                    <div className={styles.header}>Max Ticket Per Trans</div>
                    <div className={styles.header}></div>
                  </div>

                  {saleList.map((item, index) => (
                    <div
                      key={index}
                      className={`${styles.gridContainer} ${styles.values} ${styles.addSale}`}
                    >
                      <div className={styles.value}>{item?.saleName}</div>
                      <div
                        style={{
                          textTransform: "capitalize",
                        }}
                        className={styles.value}
                      >
                        {item?.releaseType}
                      </div>
                      <div className={styles.value}>
                        {getDateFormatted(item?.saleStartDate)}
                      </div>
                      <div className={styles.value}>
                        {getDateFormatted(item?.saleEndDate)}
                      </div>
                      <div className={styles.value}>
                        {item?.maxTicketPerTransaction === 0
                          ? "No Limit"
                          : item?.maxTicketPerTransaction}
                      </div>
                      <div className={styles.value}>
                        <img
                          onClick={() => handleRemoveSale(index)}
                          className={styles.closeButton}
                          src={redClose}
                          alt="close"
                        />
                      </div>
                    </div>
                  ))}

                  <div className={styles.bottomBtns}>
                    <Button onClick={handlePrevStep} text={"Back"} />
                    <Button
                      disabled={!saleList.length}
                      onClick={handleNextStep}
                      text={`Continue to Step ${currentStep + 1}`}
                    />
                  </div>
                </div>
              )} */}

              {currentStep === 3 && (
                <div className={styles.content}>
                  <div className={styles.grid}>
                    <div>
                      <div className={styles.label}>
                        {translate("Sale Name")}
                      </div>

                      <Input
                        dark={dark}
                        name="saleName"
                        type="text"
                        value={step3Inputs.saleName}
                        updateValue={handleStep3Change}
                        placeholder={"Enter Name"}
                      />
                    </div>
                    <div>
                      <div className={styles.label}>
                        {translate("Release Type")}
                      </div>
                      <Select
                        options={[{ label: "FCFS", value: "fcfs" }]}
                        dark={dark}
                        name="releaseType"
                        type="text"
                        value={step3Inputs.releaseType}
                        updateValue={(e) =>
                          setStep3Inputs({
                            ...step3Inputs,
                            releaseType: e,
                          })
                        }
                        placeholderText={"Choose Type"}
                      />
                    </div>
                    <div>
                      <div className={styles.label}>
                        {translate("Sale Start Date & Time")}
                      </div>

                      <Input
                        dark={dark}
                        name="saleStartDate"
                        type="datetime-local"
                        value={step3Inputs.saleStartDate}
                        updateValue={handleStep3Change}
                        placeholder={"Enter Date"}
                      />
                    </div>
                    <div>
                      <div className={styles.label}>
                        {translate("Sale End Date & Time")}
                      </div>

                      <Input
                        dark={dark}
                        name="saleEndDate"
                        type="datetime-local"
                        value={step3Inputs.saleEndDate}
                        updateValue={handleStep3Change}
                        placeholder={"Enter Date"}
                      />
                    </div>
                  </div>

                  {step3Inputs.releaseType !== "Raffle" && (
                    <div className={styles.grid}>
                      <div>
                        <div className={styles.label}>
                          {translate("Ticket for sale")}
                        </div>

                        <Input
                          dark={dark}
                          name="ticketForSale"
                          type="number"
                          value={step3Inputs.ticketForSale}
                          updateValue={handleStep3Change}
                          placeholder={"Enter Count"}
                        />
                      </div>
                      <div>
                        <div className={styles.label}>
                          {translate("Max. checkout per account")}
                        </div>

                        <Select
                          options={[
                            {
                              label: "No limit",
                              value: 0,
                            },
                            ...ticketLimitsOptions(),
                          ]}
                          dark={dark}
                          name="maxCheckoutPerAccount"
                          type="text"
                          value={step3Inputs.maxCheckoutPerAccount}
                          updateValue={(e) =>
                            setStep3Inputs({
                              ...step3Inputs,
                              maxCheckoutPerAccount: e,
                            })
                          }
                          placeholderText={"Choose count"}
                        />
                      </div>
                      <div>
                        <div className={styles.label}>
                          {translate("Max. ticket per transaction")}
                        </div>
                        <Select
                          options={[
                            {
                              label: "No limit",
                              value: 0,
                            },
                            ...ticketLimitsOptions(),
                          ]}
                          dark={dark}
                          name="maxTicketPerTransaction"
                          type="text"
                          value={step3Inputs.maxTicketPerTransaction}
                          updateValue={(e) =>
                            setStep3Inputs({
                              ...step3Inputs,
                              maxTicketPerTransaction: e,
                            })
                          }
                          placeholderText={"Choose count"}
                        />
                      </div>
                      <div
                        style={{
                          alignItems: "flex-end",
                        }}
                        className={styles.row}
                      >
                        <Button
                          onClick={handleAddSale}
                          imgSrc={<img src={addSvg} alt="add" />}
                          text={`Add Sale`}
                        />
                      </div>
                    </div>
                  )}

                  <div
                    className={`${styles.gridContainer} ${styles.headers} ${styles.addSale}`}
                  >
                    <div className={styles.header}>Sale Name</div>
                    <div className={styles.header}>Release Type</div>
                    <div className={styles.header}>Sale Start</div>
                    <div className={styles.header}>Sale End</div>
                    <div className={styles.header}>Max Ticket Per Trans</div>
                    <div className={styles.header}></div>
                  </div>

                  {saleList.map((item, index) => (
                    <div
                      key={index}
                      className={`${styles.gridContainer} ${styles.values} ${styles.addSale}`}
                    >
                      <div className={styles.value}>{item?.saleName}</div>
                      <div
                        style={{
                          textTransform: "capitalize",
                        }}
                        className={styles.value}
                      >
                        {item?.releaseType}
                      </div>
                      <div className={styles.value}>
                        {formatLocalDateTime(item?.saleStartDate)}
                      </div>
                      <div className={styles.value}>
                        {formatLocalDateTime(item?.saleEndDate)}
                      </div>
                      <div className={styles.value}>
                        {item?.maxTicketPerTransaction === 0
                          ? "No Limit"
                          : item?.maxTicketPerTransaction}
                      </div>
                      <div className={styles.value}>
                        <img
                          onClick={() => handleRemoveSale(index)}
                          className={styles.closeButton}
                          src={redClose}
                          alt="close"
                        />
                      </div>
                    </div>
                  ))}

                  <div className={styles.bottomBtns}>
                    <Button onClick={handlePrevStep} text={"Back"} />
                    <Button
                      disabled={!saleList.length}
                      onClick={handleNextStep}
                      text={`Continue to Step ${currentStep + 1}`}
                    />
                  </div>
                </div>
              )}

              {currentStep === 4 && (
                <div className={`${styles.content} ${styles.margin}`}>
                  <div className={styles.inputContainer}>
                    <div className={styles.label}>
                      {translate("Event Details")}
                    </div>
                    <textarea
                      value={eventsDetail}
                      onChange={(e) => setEventsDetail(e.target.value)}
                      className={styles.textarea}
                      placeholder="Enter details here"
                    />
                    <p className={styles.terms}>
                      Kindly review the event carefully before you submit it to
                      Ingenium+. Your submission will be reviewed and go live
                      within 2 business days. Please take note that Ingenium+
                      reserve all rights to revoke or modify your event if your
                      company violates any terms of service.
                    </p>
                  </div>
                  <div className={styles.bottomBtns}>
                    <Button onClick={handlePrevStep} text={"Back"} />
                    <div className="flex al-ce">
                      <Button
                        disabled={loading}
                        onClick={previewEvent}
                        text={`Preview`}
                        style={{
                          marginRight: "1rem",
                        }}
                      />
                      <Button
                        disabled={loading}
                        imgSrc={<img src={addSvg} alt="add" />}
                        onClick={addEvent}
                        text={`Submit`}
                      />
                      <CircularLoader loading={loading} />
                    </div>
                  </div>
                </div>
              )}
            </div>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
